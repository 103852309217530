!(function (t, e) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = e())
    : "function" == typeof define && define.amd
    ? define("flipbook", [], e)
    : "object" == typeof exports
    ? (exports.flipbook = e())
    : (t.flipbook = e());
})(self, () =>
  (() => {
    "use strict";
    var t = {
        194: (t) => {
          function e(t) {
            let e = document.createElement(t.tag);
            return n(e), e.attr(t.attr), e.add(t.children), e;
          }
          function n(t) {
            if (t)
              return (
                t.c ||
                  (t.c = function () {
                    return (
                      1 === arguments.length && "string" == typeof arguments[0]
                        ? (t.innerHTML = arguments[0])
                        : ((t.innerHTML = ""),
                          arguments.length &&
                            s(t, Array.prototype.slice.call(arguments))),
                      t
                    );
                  }),
                (t.attr = (e) => i(t, e)),
                (t.add = (e) => s(t, e)),
                (t.rm = (e) => t.removeChild(e)),
                (t.addClass = (e) =>
                  (function (t, e) {
                    if (t.classList && t.classList.add)
                      return t.classList.add(e);
                    const n = t.getAttribute("class");
                    n
                      ? t.setAttribute("class", `${n} ${e}`)
                      : t.setAttribute("class", e);
                  })(t, e)),
                (t.rmClass = (e) =>
                  (function (t, e) {
                    if (t.classList && t.classList.remove)
                      return t.classList.remove(e);
                    const n = t.getAttribute("class");
                    if (!n) return;
                    const o = n
                      .split(" ")
                      .filter((t) => t !== e)
                      .join(" ");
                    t.setAttribute("class", o);
                  })(t, e)),
                t
              );
          }
          function o(t, e, n, o) {
            const r = { tag: null, attr: {}, children: [] };
            return i(e), i(n), i(o), r;
            function i(e) {
              if (!r.tag) {
                if (!e) return void (r.tag = t);
                if ("string" == typeof e) return void (r.tag = e);
                r.tag = t;
              }
              e &&
                (Array.isArray(e) ||
                a(e) ||
                "string" == typeof e ||
                "number" == typeof e
                  ? (r.children = r.children.concat(e))
                  : (r.attr = Object.assign(r.attr, e)));
            }
          }
          function r(t, e, n) {
            n.class && n.classes
              ? (n.class += " " + n.classes)
              : n.classes
              ? (n.class = n.classes)
              : n.class || (n.class = ""),
              delete n.classes;
            let o = (e = e.replace(/#/g, ".#")).split(".");
            (e = o.shift()) || (e = t);
            for (let t = 0; t < o.length; t++)
              "#" == o[t][0]
                ? n.id || (n.id = o[t].substring(1))
                : (n.class += " " + o[t]);
            return n.class || delete n.class, e;
          }
          function i(t, e) {
            if (e)
              for (let n in e)
                if ("class" === n || "classes" === n) {
                  if (e[n]) {
                    const o = t.getAttribute("class");
                    let r = e[n]
                      .trim()
                      .split(/[ \t]+/g)
                      .join(" ");
                    o && (r += " " + o), t.setAttribute("class", r);
                  }
                } else if ("style" === n) {
                  let o = e[n];
                  if ("string" == typeof o) t.style.cssText = o;
                  else for (let e in o) t.style.setProperty(e, o[e]);
                } else
                  n.startsWith("on")
                    ? t.addEventListener(n.substring(2).toLowerCase(), e[n])
                    : !1 === e[n]
                    ? t.removeAttribute(n)
                    : e[n]
                    ? t.setAttribute(n, e[n])
                    : t.setAttribute(n, "");
          }
          function s(t, e) {
            if (e) {
              Array.isArray(e) || (e = [e]);
              for (let n = 0; n < e.length; n++) {
                let o = e[n];
                o &&
                  (Array.isArray(o)
                    ? s(t, o)
                    : (a(o) || (o = document.createTextNode(o)),
                      t.appendChild(o)));
              }
            }
          }
          function a(t) {
            return t && t.nodeName && t.nodeType;
          }
          function c(t, n) {
            let i = o("div", t, n);
            return (
              (i.tag = r("div", i.tag, i.attr)),
              (t, n, s) => {
                let a = o("div", t, n, s);
                return (
                  (a.tag = r("div", a.tag, a.attr)),
                  i.attr.class &&
                    a.attr.class &&
                    (a.attr.class = i.attr.class + " " + a.attr.class),
                  i.attr.style &&
                    a.attr.style &&
                    typeof i.attr.style == typeof a.attr.style &&
                    ("string" == typeof i.attr.style
                      ? (a.attr.style = i.attr.style + ";" + a.attr.style)
                      : (a.attr.style = Object.assign(
                          {},
                          i.attr.style,
                          a.attr.style
                        ))),
                  (a.attr = Object.assign({}, i.attr, a.attr)),
                  e(a)
                );
              }
            );
          }
          t.exports = {
            h: function (t, n, i) {
              let s = o("div", t, n, i);
              return (s.tag = r("div", s.tag, s.attr)), e(s);
            },
            wrap: n,
            getH: function (t, e) {
              return t instanceof Element
                ? n(t)
                : (e || (e = document),
                  e.contentDocument && (e = e.contentDocument),
                  n(e.getElementById(t)));
            },
            x: c,
            div: c("div"),
            svg: function (t, e, a) {
              let c,
                l = o("svg", t, e, a);
              if (((l.tag = r("svg", l.tag, l.attr)), "<" == l.tag[0])) {
                (c = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "svg"
                )),
                  (c.innerHTML = t);
                const e = c.getElementsByTagName("svg")[0];
                e && (c = e);
              } else
                (c = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  l.tag
                )),
                  i(c, l.attr),
                  s(c, l.children);
              return n(c), c;
            },
          };
        },
        187: (t) => {
          var e,
            n = "object" == typeof Reflect ? Reflect : null,
            o =
              n && "function" == typeof n.apply
                ? n.apply
                : function (t, e, n) {
                    return Function.prototype.apply.call(t, e, n);
                  };
          e =
            n && "function" == typeof n.ownKeys
              ? n.ownKeys
              : Object.getOwnPropertySymbols
              ? function (t) {
                  return Object.getOwnPropertyNames(t).concat(
                    Object.getOwnPropertySymbols(t)
                  );
                }
              : function (t) {
                  return Object.getOwnPropertyNames(t);
                };
          var r =
            Number.isNaN ||
            function (t) {
              return t != t;
            };
          function i() {
            i.init.call(this);
          }
          (t.exports = i),
            (t.exports.once = function (t, e) {
              return new Promise(function (n, o) {
                function r(n) {
                  t.removeListener(e, i), o(n);
                }
                function i() {
                  "function" == typeof t.removeListener &&
                    t.removeListener("error", r),
                    n([].slice.call(arguments));
                }
                g(t, e, i, { once: !0 }),
                  "error" !== e &&
                    (function (t, e, n) {
                      "function" == typeof t.on &&
                        g(t, "error", e, { once: !0 });
                    })(t, r);
              });
            }),
            (i.EventEmitter = i),
            (i.prototype._events = void 0),
            (i.prototype._eventsCount = 0),
            (i.prototype._maxListeners = void 0);
          var s = 10;
          function a(t) {
            if ("function" != typeof t)
              throw new TypeError(
                'The "listener" argument must be of type Function. Received type ' +
                  typeof t
              );
          }
          function c(t) {
            return void 0 === t._maxListeners
              ? i.defaultMaxListeners
              : t._maxListeners;
          }
          function l(t, e, n, o) {
            var r, i, s, l;
            if (
              (a(n),
              void 0 === (i = t._events)
                ? ((i = t._events = Object.create(null)), (t._eventsCount = 0))
                : (void 0 !== i.newListener &&
                    (t.emit("newListener", e, n.listener ? n.listener : n),
                    (i = t._events)),
                  (s = i[e])),
              void 0 === s)
            )
              (s = i[e] = n), ++t._eventsCount;
            else if (
              ("function" == typeof s
                ? (s = i[e] = o ? [n, s] : [s, n])
                : o
                ? s.unshift(n)
                : s.push(n),
              (r = c(t)) > 0 && s.length > r && !s.warned)
            ) {
              s.warned = !0;
              var f = new Error(
                "Possible EventEmitter memory leak detected. " +
                  s.length +
                  " " +
                  String(e) +
                  " listeners added. Use emitter.setMaxListeners() to increase limit"
              );
              (f.name = "MaxListenersExceededWarning"),
                (f.emitter = t),
                (f.type = e),
                (f.count = s.length),
                (l = f),
                console && console.warn && console.warn(l);
            }
            return t;
          }
          function f() {
            if (!this.fired)
              return (
                this.target.removeListener(this.type, this.wrapFn),
                (this.fired = !0),
                0 === arguments.length
                  ? this.listener.call(this.target)
                  : this.listener.apply(this.target, arguments)
              );
          }
          function u(t, e, n) {
            var o = {
                fired: !1,
                wrapFn: void 0,
                target: t,
                type: e,
                listener: n,
              },
              r = f.bind(o);
            return (r.listener = n), (o.wrapFn = r), r;
          }
          function h(t, e, n) {
            var o = t._events;
            if (void 0 === o) return [];
            var r = o[e];
            return void 0 === r
              ? []
              : "function" == typeof r
              ? n
                ? [r.listener || r]
                : [r]
              : n
              ? (function (t) {
                  for (var e = new Array(t.length), n = 0; n < e.length; ++n)
                    e[n] = t[n].listener || t[n];
                  return e;
                })(r)
              : d(r, r.length);
          }
          function p(t) {
            var e = this._events;
            if (void 0 !== e) {
              var n = e[t];
              if ("function" == typeof n) return 1;
              if (void 0 !== n) return n.length;
            }
            return 0;
          }
          function d(t, e) {
            for (var n = new Array(e), o = 0; o < e; ++o) n[o] = t[o];
            return n;
          }
          function g(t, e, n, o) {
            if ("function" == typeof t.on) o.once ? t.once(e, n) : t.on(e, n);
            else {
              if ("function" != typeof t.addEventListener)
                throw new TypeError(
                  'The "emitter" argument must be of type EventEmitter. Received type ' +
                    typeof t
                );
              t.addEventListener(e, function r(i) {
                o.once && t.removeEventListener(e, r), n(i);
              });
            }
          }
          Object.defineProperty(i, "defaultMaxListeners", {
            enumerable: !0,
            get: function () {
              return s;
            },
            set: function (t) {
              if ("number" != typeof t || t < 0 || r(t))
                throw new RangeError(
                  'The value of "defaultMaxListeners" is out of range. It must be a non-negative number. Received ' +
                    t +
                    "."
                );
              s = t;
            },
          }),
            (i.init = function () {
              (void 0 !== this._events &&
                this._events !== Object.getPrototypeOf(this)._events) ||
                ((this._events = Object.create(null)), (this._eventsCount = 0)),
                (this._maxListeners = this._maxListeners || void 0);
            }),
            (i.prototype.setMaxListeners = function (t) {
              if ("number" != typeof t || t < 0 || r(t))
                throw new RangeError(
                  'The value of "n" is out of range. It must be a non-negative number. Received ' +
                    t +
                    "."
                );
              return (this._maxListeners = t), this;
            }),
            (i.prototype.getMaxListeners = function () {
              return c(this);
            }),
            (i.prototype.emit = function (t) {
              for (var e = [], n = 1; n < arguments.length; n++)
                e.push(arguments[n]);
              var r = "error" === t,
                i = this._events;
              if (void 0 !== i) r = r && void 0 === i.error;
              else if (!r) return !1;
              if (r) {
                var s;
                if ((e.length > 0 && (s = e[0]), s instanceof Error)) throw s;
                var a = new Error(
                  "Unhandled error." + (s ? " (" + s.message + ")" : "")
                );
                throw ((a.context = s), a);
              }
              var c = i[t];
              if (void 0 === c) return !1;
              if ("function" == typeof c) o(c, this, e);
              else {
                var l = c.length,
                  f = d(c, l);
                for (n = 0; n < l; ++n) o(f[n], this, e);
              }
              return !0;
            }),
            (i.prototype.addListener = function (t, e) {
              return l(this, t, e, !1);
            }),
            (i.prototype.on = i.prototype.addListener),
            (i.prototype.prependListener = function (t, e) {
              return l(this, t, e, !0);
            }),
            (i.prototype.once = function (t, e) {
              return a(e), this.on(t, u(this, t, e)), this;
            }),
            (i.prototype.prependOnceListener = function (t, e) {
              return a(e), this.prependListener(t, u(this, t, e)), this;
            }),
            (i.prototype.removeListener = function (t, e) {
              var n, o, r, i, s;
              if ((a(e), void 0 === (o = this._events))) return this;
              if (void 0 === (n = o[t])) return this;
              if (n === e || n.listener === e)
                0 == --this._eventsCount
                  ? (this._events = Object.create(null))
                  : (delete o[t],
                    o.removeListener &&
                      this.emit("removeListener", t, n.listener || e));
              else if ("function" != typeof n) {
                for (r = -1, i = n.length - 1; i >= 0; i--)
                  if (n[i] === e || n[i].listener === e) {
                    (s = n[i].listener), (r = i);
                    break;
                  }
                if (r < 0) return this;
                0 === r
                  ? n.shift()
                  : (function (t, e) {
                      for (; e + 1 < t.length; e++) t[e] = t[e + 1];
                      t.pop();
                    })(n, r),
                  1 === n.length && (o[t] = n[0]),
                  void 0 !== o.removeListener &&
                    this.emit("removeListener", t, s || e);
              }
              return this;
            }),
            (i.prototype.off = i.prototype.removeListener),
            (i.prototype.removeAllListeners = function (t) {
              var e, n, o;
              if (void 0 === (n = this._events)) return this;
              if (void 0 === n.removeListener)
                return (
                  0 === arguments.length
                    ? ((this._events = Object.create(null)),
                      (this._eventsCount = 0))
                    : void 0 !== n[t] &&
                      (0 == --this._eventsCount
                        ? (this._events = Object.create(null))
                        : delete n[t]),
                  this
                );
              if (0 === arguments.length) {
                var r,
                  i = Object.keys(n);
                for (o = 0; o < i.length; ++o)
                  "removeListener" !== (r = i[o]) && this.removeAllListeners(r);
                return (
                  this.removeAllListeners("removeListener"),
                  (this._events = Object.create(null)),
                  (this._eventsCount = 0),
                  this
                );
              }
              if ("function" == typeof (e = n[t])) this.removeListener(t, e);
              else if (void 0 !== e)
                for (o = e.length - 1; o >= 0; o--)
                  this.removeListener(t, e[o]);
              return this;
            }),
            (i.prototype.listeners = function (t) {
              return h(this, t, !0);
            }),
            (i.prototype.rawListeners = function (t) {
              return h(this, t, !1);
            }),
            (i.listenerCount = function (t, e) {
              return "function" == typeof t.listenerCount
                ? t.listenerCount(e)
                : p.call(t, e);
            }),
            (i.prototype.listenerCount = p),
            (i.prototype.eventNames = function () {
              return this._eventsCount > 0 ? e(this._events) : [];
            });
        },
      },
      e = {};
    function n(o) {
      var r = e[o];
      if (void 0 !== r) return r.exports;
      var i = (e[o] = { exports: {} });
      return t[o](i, i.exports, n), i.exports;
    }
    (n.d = (t, e) => {
      for (var o in e)
        n.o(e, o) &&
          !n.o(t, o) &&
          Object.defineProperty(t, o, { enumerable: !0, get: e[o] });
    }),
      (n.o = (t, e) => Object.prototype.hasOwnProperty.call(t, e)),
      (n.r = (t) => {
        "undefined" != typeof Symbol &&
          Symbol.toStringTag &&
          Object.defineProperty(t, Symbol.toStringTag, { value: "Module" }),
          Object.defineProperty(t, "__esModule", { value: !0 });
      });
    var o = {};
    return (
      (() => {
        n.r(o), n.d(o, { init: () => d });
        var t = n(194),
          e = n(187);
        class r extends e {}
        const i = window.devicePixelRatio || 1;
        function s(e, n) {
          const o = new r();
          (o.page_count = e.book.numPages()),
            (function (e, n) {
              const o = { e: (0, t.h)("canvas") };
              (o.ctx = o.e.getContext("2d")),
                (o.e.width = Math.floor(e.sz.boxw * i)),
                (o.e.height = Math.floor(e.sz.boxh * i)),
                (o.e.style.width = Math.floor(e.sz.boxw) + "px"),
                (o.e.style.height = Math.floor(e.sz.boxh) + "px"),
                (e.canvas = o),
                n();
            })(e, (t) => {
              if (t) return n(t);
              !(function (t, e) {
                const n = t.sz.boxw * i,
                  o = t.sz.boxh * i;
                t.book.getPage(1, (r, i) => {
                  if (r) return e(r);
                  const s = 1 - t.sz.marginTop / 100;
                  let a = o * s;
                  const c = 1 - t.sz.marginLeft / 100;
                  let l = 2 * i.width * (a / i.height);
                  const f = n * c;
                  l > f && ((l = f), (a = i.height * (l / (2 * i.width)))),
                    (t.layout = {
                      top: (o - a) / 2,
                      left: (n - l) / 2,
                      mid: n / 2,
                      width: l,
                      height: a,
                    }),
                    e();
                });
              })(e, (t) => {
                if (t) return n(t);
                e.app.c(e.canvas.e),
                  (function (t, e) {
                    const n = [a(t, e)],
                      o = {};
                    [
                      "onmouseenter",
                      "onmouseleave",
                      "onmousemove",
                      "onclick",
                      "onmousedown",
                      "onmouseup",
                    ].map((t) => {
                      o[t] = (e) => {
                        n.map((n) => {
                          n[t] && n[t](e);
                        });
                      };
                    }),
                      t.app.attr(o);
                  })(e, o),
                  (e.zoom = 0),
                  (e.showNdx = 0),
                  (function (t, e) {
                    function n(t) {
                      u({
                        draw: (n) => {
                          (t.flipFrac = n.flipFrac),
                            (function (t, e) {
                              f(t, e);
                              const n = t.canvas,
                                o = 2 * t.flipNdx,
                                r = o + 1,
                                i = l(t),
                                s = 0.5 - Math.abs(0.5 - t.flipFrac);
                              function a(e, o) {
                                n.ctx.fillStyle = e;
                                const r = t.sz.bx_border;
                                n.ctx.fillRect(
                                  o.left - r,
                                  o.top - r - 5,
                                  o.width + 2 * r,
                                  o.height + 2 * r + 10
                                );
                              }
                              function c(t, e) {
                                const n = t.sz.bx_border;
                                return {
                                  left: e.left - n,
                                  top: e.top - n,
                                  width: e.width + 2 * n,
                                  height: e.height + 2 * n,
                                };
                              }
                              n.ctx.save(),
                                t.book.getPage(o, (e, o) => {
                                  if (e) return console.error(e);
                                  t.book.getPage(r, (e, r) => {
                                    if (e) return console.error(e);
                                    !(function (e, o, r, l) {
                                      let f, u, h, p, d, g, m, v, x;
                                      if (t.showNdx < t.flipNdx) {
                                        (f = Object.assign({}, i)),
                                          (f.width /= 2),
                                          (f.left = i.mid),
                                          (u = f.left + (1 - r) * f.width),
                                          (h = f.width * r),
                                          (d = c(t, f)),
                                          n.ctx.save(),
                                          (p = new Path2D()),
                                          p.rect(
                                            u,
                                            d.top - 5,
                                            h,
                                            d.height + 10
                                          ),
                                          n.ctx.clip(p),
                                          o
                                            ? n.ctx.drawImage(
                                                o.img,
                                                f.left,
                                                f.top,
                                                f.width,
                                                f.height
                                              )
                                            : a(t.color.bg, d),
                                          n.ctx.restore(),
                                          (f = Object.assign({}, i)),
                                          (f.left += (1 - r) * f.width),
                                          (f.width /= 2),
                                          (h = f.width * r),
                                          (g = f.height),
                                          (m = f.top),
                                          (f.height *= 1 + 0.1 * s),
                                          (f.top -= (f.height - g) / 2),
                                          n.ctx.save(),
                                          (p = new Path2D()),
                                          p.moveTo(f.left, m),
                                          p.lineTo(f.left, m + g),
                                          (v = {
                                            x: f.left + h / 2,
                                            y: f.top + f.height,
                                          }),
                                          (x = { x: f.left + h, y: m + g }),
                                          p.quadraticCurveTo(
                                            v.x,
                                            v.y,
                                            x.x,
                                            x.y
                                          ),
                                          p.lineTo(x.x, m),
                                          (v = { x: f.left + h, y: f.top }),
                                          (x = { x: f.left, y: m }),
                                          p.quadraticCurveTo(
                                            v.x,
                                            v.y,
                                            x.x,
                                            x.y
                                          ),
                                          n.ctx.clip(p),
                                          n.ctx.drawImage(
                                            e.img,
                                            f.left,
                                            f.top,
                                            f.width,
                                            f.height
                                          ),
                                          n.ctx.restore(),
                                          n.ctx.save();
                                        const l =
                                          (f.width / 2) *
                                          Math.max(Math.min(s, 0.5), 0);
                                        (n.ctx.strokeStyle =
                                          "rgba(0,0,0," + 0.1 * s + ")"),
                                          (n.ctx.lineWidth = 30 * s),
                                          n.ctx.beginPath(),
                                          n.ctx.moveTo(f.left, m),
                                          n.ctx.lineTo(f.left, m + g),
                                          n.ctx.stroke();
                                        let y = n.ctx.createLinearGradient(
                                          f.left + h,
                                          m,
                                          f.left + h + l,
                                          m
                                        );
                                        y.addColorStop(
                                          0,
                                          "rgba(0,0,0," + 0.3 * s + ")"
                                        ),
                                          y.addColorStop(
                                            0.8,
                                            "rgba(0,0,0,0.0)"
                                          ),
                                          (n.ctx.fillStyle = y),
                                          n.ctx.fillRect(
                                            f.left + h,
                                            m,
                                            h + l,
                                            g
                                          ),
                                          n.ctx.restore();
                                      } else {
                                        (f = Object.assign({}, i)),
                                          (f.width /= 2),
                                          (h = f.width * r + t.sz.bx_border),
                                          (d = c(t, f)),
                                          n.ctx.save(),
                                          (p = new Path2D()),
                                          p.rect(
                                            d.left,
                                            d.top - 5,
                                            h,
                                            d.height + 10
                                          ),
                                          n.ctx.clip(p),
                                          e
                                            ? n.ctx.drawImage(
                                                e.img,
                                                f.left,
                                                f.top,
                                                f.width,
                                                f.height
                                              )
                                            : a(t.color.bg, f),
                                          n.ctx.restore(),
                                          (f = Object.assign({}, i)),
                                          (f.width /= 2),
                                          (u = f.left + r * f.width),
                                          (f.left = u - (1 - r) * f.width),
                                          (h = f.width * r),
                                          (g = f.height),
                                          (m = f.top),
                                          (f.height *= 1 + 0.1 * s),
                                          (f.top -= (f.height - g) / 2),
                                          n.ctx.save(),
                                          (p = new Path2D()),
                                          p.moveTo(u, m),
                                          p.lineTo(u, m + g),
                                          (v = {
                                            x: u + h / 2,
                                            y: f.top + f.height,
                                          }),
                                          (x = { x: u + h, y: m + g }),
                                          p.quadraticCurveTo(
                                            v.x,
                                            v.y,
                                            x.x,
                                            x.y
                                          ),
                                          p.lineTo(x.x, m),
                                          (v = { x: u + h, y: f.top }),
                                          (x = { x: u, y: m }),
                                          p.quadraticCurveTo(
                                            v.x,
                                            v.y,
                                            x.x,
                                            x.y
                                          ),
                                          n.ctx.clip(p),
                                          n.ctx.drawImage(
                                            o.img,
                                            f.left,
                                            f.top,
                                            f.width,
                                            f.height
                                          ),
                                          n.ctx.restore(),
                                          n.ctx.save();
                                        const l =
                                          (f.width / 2) *
                                          Math.max(Math.min(s, 0.5), 0);
                                        (n.ctx.strokeStyle =
                                          "rgba(0,0,0," + 0.1 * s + ")"),
                                          (n.ctx.lineWidth = 30 * s),
                                          n.ctx.beginPath(),
                                          n.ctx.moveTo(u + h, m),
                                          n.ctx.lineTo(u + h, m + g),
                                          n.ctx.stroke();
                                        let y = n.ctx.createLinearGradient(
                                          u,
                                          m,
                                          u - l,
                                          m
                                        );
                                        y.addColorStop(
                                          0,
                                          "rgba(0,0,0," + 0.3 * s + ")"
                                        ),
                                          y.addColorStop(
                                            0.8,
                                            "rgba(0,0,0,0.0)"
                                          ),
                                          (n.ctx.fillStyle = y),
                                          n.ctx.fillRect(u - l, m, l, g),
                                          n.ctx.restore();
                                      }
                                      l();
                                    })(o, r, t.flipFrac, () => n.ctx.restore());
                                  });
                                });
                            })(t, e);
                        },
                        duration: 1111,
                        from: { flipFrac: 0 },
                        to: { flipFrac: 1 },
                        timing: (t) => t * t * (3 - 2 * t),
                        ondone: () => {
                          (t.showNdx = t.flipNdx), (t.flipNdx = null), f(t, e);
                        },
                      });
                    }
                    (e.zoom = (n) => {
                      (n = Number(n)),
                        isNaN(n) && (n = 2 * t.zoom + 1) > 4 && (n = 0),
                        n
                          ? u({
                              draw: (n) => {
                                (t.zoom = n.zoom), f(t, e);
                              },
                              duration: 500,
                              from: { zoom: t.zoom },
                              to: { zoom: n },
                              timing: (t) => t * t * (3 - 2 * t),
                            })
                          : ((t.zoom = 0), (t.pan = null), f(t, e));
                    }),
                    (e.zoom_out = (n) => {
                      u({
                        draw: (n) => {
                          (t.zoom= 0), f(t, e);
                        },
                        duration: 500,
                        from: { zoom: t.zoom },
                        to: { zoom: n },
                        timing: (t) => t * t * (3 - 2 * t),
                      })
                        
                    }),
                      (e.flip_forward = () => {
                        t.flipNdx ||
                          0 === t.flipNdx ||
                          t.book.numPages() <= 1 ||
                          2 * t.showNdx + 1 >= t.book.numPages() ||
                          ((t.flipNdx = t.showNdx + 1), n(t));
                      }),
                      (e.flip_back = () => {
                        t.flipNdx ||
                          0 === t.flipNdx ||
                          t.book.numPages() <= 1 ||
                          (t.showNdx && ((t.flipNdx = t.showNdx - 1), n(t)));
                      });
                  })(e, o),
                  n(null, o),
                  f(e, o);
              });
            });
        }
        function a(t, e) {
          let n;
          return {
            onmouseleave: function (t) {
              n = null;
            },
            onmousedown: function (e) {
              t.zoom &&
                ((n = c(t, e)), t.pan && ((n.x -= t.pan.x), (n.y -= t.pan.y)));
            },
            onmouseup: function (t) {
              n = null;
            },
            onmousemove: function (o) {
              const r = c(t, o);
              n &&
              (function (t, e) {
                const n = (function (t) {
                  const e = l(t);
                  return {
                    top: e.top,
                    left: e.left,
                    bottom: e.top + e.height,
                    right: e.left + e.width,
                  };
                })(t);
                return (
                  n.top <= e.y &&
                  n.bottom >= e.y &&
                  n.left <= e.x &&
                  n.right >= e.x
                );
              })(t, r)
                ? ((t.pan = { x: r.x - n.x, y: r.y - n.y }), f(t, e))
                : (n = null);
            },
          };
        }
        function c(t, e) {
          const n = t.app.getBoundingClientRect();
          return { x: e.clientX - n.x, y: e.clientY - n.y };
        }
        function l(t) {
          let e = t.layout;
          if (t.zoom > 0) {
            if (((e = Object.assign({}, e)), t.zoom)) {
              const n = 0.5 * t.zoom;
              (e.left = e.left - (e.width * n) / 2),
                (e.top = e.top - (e.height * n) / 2),
                (e.width = e.width * (1 + n)),
                (e.height = e.height * (1 + n));
            }
            t.pan &&
              ((e.left += t.pan.x), (e.top += t.pan.y), (e.mid += t.pan.x));
          }
          return e;
        }
        function f(t, e) {
          const n = t.canvas,
            o = 2 * t.showNdx,
            r = o + 1;
          function s(t, e) {
            n.ctx.drawImage(t.img, e.left, e.top, e.width, e.height);
          }
          n.ctx.save(),
            (n.ctx.fillStyle = t.color.bg),
            n.ctx.fillRect(0, 0, t.sz.boxw * i, t.sz.boxh * i),
            t.book.getPage(o, (i, a) => {
              if (i) return console.error(i);
              !t.flipNdx && 0 !== t.flipNdx && a && e.emit("seen", o),
                t.book.getPage(r, (o, i) => {
                  if (o) return console.error(o);
                  !t.flipNdx && 0 !== t.flipNdx && i && e.emit("seen", r),
                    (function (e, o, r) {
                      const i = l(t);
                      0 == t.zoom &&
                        (function (e) {
                          n.ctx.fillStyle = t.color.bx;
                          const o = t.sz.bx_border;
                          n.ctx.fillRect(
                            e.left - o,
                            e.top - o,
                            e.width + 2 * o,
                            e.height + 2 * o
                          );
                        })(i);
                      const a = Object.assign({}, i),
                        c = Object.assign({}, i);
                      (a.width /= 2),
                        (c.width /= 2),
                        (c.left = i.mid),
                        e && s(e, a),
                        o && s(o, c),
                        n.ctx.restore();
                    })(a, i);
                });
            });
        }
        function u({
          draw: t,
          duration: e,
          from: n,
          to: o,
          timing: r,
          ondone: i,
        }) {
          i || (i = () => 1), r || (r = (t) => t);
          const s = Date.now();
          !(function a() {
            let c = (Date.now() - s) / e;
            c > 1 && (c = 1);
            const l = (function (t) {
              t = r(t);
              const e = Object.assign({}, n);
              for (let r in n) {
                const i = Number(n[r]),
                  s = Number(o[r]);
                e[r] = i + (s - i) * t;
              }
              return e;
            })(c);
            t(l), 1 === c ? i() : requestAnimationFrame(a);
          })();
        }
        class h extends e {}
        const p = (e) => (0, t.h)(`canvas#flipbook__pgnum_${e}.flipbook__page`);
        function d(e, n, o, r) {
          "function" == typeof o && ((r = o), (o = {})),
            o || (o = {}),
            r || (r = () => 1);
          const i = (0, t.getH)(n);
          if (!i) {
            const t =
              "flipbook-viewer: Failed to find container for viewer: " + n;
            return console.error(t), void r(t);
          }
          if (o.singlepage)
            !(function (t, e) {
              const n = new h();
              (n.page_count = t.book.numPages()),
                (function (t, e) {
                  const n = p(0);
                  t.app.c(n),
                    setTimeout(() => {
                      (t.page_width = n.getBoundingClientRect().width),
                        t.app.rm(n),
                        e();
                    });
                })(t, (o) => {
                  if (o) return e(o);
                  !(function (t, e) {
                    const n = window.devicePixelRatio || 1,
                      o = t.book.pdf;
                    (t.pages = []),
                      (function r(i) {
                        if (i >= o.numPages) return e();
                        const s = i + 1;
                        o.getPage(s)
                          .then((o) => {
                            const a = o.getViewport({ scale: 1 }),
                              c = t.page_width / a.width,
                              l = o.getViewport({ scale: c }),
                              f = p(s);
                            f.attr({ "data-flipbook-page": s }),
                              (f.width = Math.floor(l.width * n)),
                              (f.height = Math.floor(l.height * n)),
                              (f.style.width = Math.floor(l.width) + "px"),
                              (f.style.height = Math.floor(l.height) + "px"),
                              t.pages.push(f),
                              t.app.add(f);
                            const u = 1 !== n ? [n, 0, 0, n, 0, 0] : null,
                              h = {
                                canvasContext: f.getContext("2d"),
                                transform: u,
                                viewport: l,
                              };
                            o.render(h)
                              .promise.then(() => r(i + 1))
                              .catch((t) =>
                                e(t || "Failed rendering page" + s)
                              );
                          })
                          .catch((t) => e(t || "Failed getting page:" + s));
                      })(0);
                  })(t, (o) => {
                    if (o) return e(o);
                    e(null, n),
                      (function (t, e) {
                        const n = {},
                          o = new IntersectionObserver(
                            function (t) {
                              t.forEach((t) => {
                                if (t.intersectionRatio)
                                  try {
                                    const o = t.target.dataset.flipbookPage;
                                    if (n[o]) return;
                                    (n[o] = !0), e.emit("seen", o);
                                  } catch (t) {
                                    console.error(t);
                                  }
                              });
                            },
                            { root: null, rootMargin: "0px", threshold: 0.25 }
                          );
                        t.pages.forEach((t) => o.observe(t));
                      })(t, n);
                  });
                });
            })({ app: i, book: e }, a);
          else {
            const t = {
                color: { bg: o.backgroundColor || "#353535" },
                sz: {
                  bx_border: o.boxBorder || 0,
                  boxw: o.width || 800,
                  boxh: o.height || 600,
                },
                app: i,
                book: e,
              },
              n = o.margin || 1;
            o.marginTop || 0 === o.marginTop
              ? (t.sz.marginTop = o.marginTop)
              : (t.sz.marginTop = n),
              o.marginLeft || 0 === o.marginLeft
                ? (t.sz.marginLeft = o.marginLeft)
                : (t.sz.marginLeft = n),
              s(t, a);
          }
          function a(t, e) {
            return (
              o.popup && history.pushState({}, "", "#"),
              e && (e.version = "1.6.1"),
              r(t, e)
            );
          }
        }
      })(),
      o
    );
  })()
);
