<template>
  <div :id="isMobile() ? 'SViewer-mobile' : 'SViewer'">
    <div class="SVTitle" id="SVTitle">Assinaturas digitais</div>
    <section class="SVContent">
      <div class="SVListItem">
        <div
          v-for="signer in Signers"
          :key="signer.SignatureDate"
          class="SVItem">

          <h4 class="SVItemRows">{{ signer.Name }}</h4>

          <small class="SVItemRows">
            <b>Expedido em:</b> {{signer.EffectiveDateString | formatDate}}
          </small>

          <small class="SVItemRows">
            <b>Válido até:</b> {{signer.ExpirationDateString | formatDate}}
          </small>

          <small class="SVItemRows">
            <b>Data da assinatura:</b> {{signer.SignatureDate | formatDate}}
          </small>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: "VSideBar",

    props: {
      Signers: Array,
    },
    
    methods: {
      isMobile() {
        if (screen.width <= 760) {
          return true;
        } else {
          return false;
        }
      },
    },

    filters: {
      formatDate: function (value) {
        if (!value)
          return ''
        
          return moment(value)
            .format('MM/DD/YYYY hh:mm')
      }
    }
  };
</script>

<style scoped>
  #SViewer {
    border-radius: 2px;
    position: absolute;
    visibility: hidden;
    margin-top: 8.2em;
    left: 71.7%;
    width: 28%;
    height: 95%;
    background: #eaebee;
  }

  #SViewer-mobile {
    border-radius: 2px;
    position: absolute;
    visibility: hidden;
    top: 0;
    margin-top: 8.4em;
    width: 100%;
    height: 95%;
    background: #eaebee;
  }

  .SVContent {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    align-items: center;
    width: 100%;
    height: 88%;
  }

  .SVTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2em;
    font-size: 2em;
    color: #5e5e5e;
    padding-left: 0.5em;
    margin-block-end: 1em;
  }

  .SVListItem {
    display: flex;
    overflow: auto; 
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-top: 0.5em;
    margin-left: 1em;
  }

  .SVItem {
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    width: 96%;
    -webkit-box-shadow: 0px 2px 13px 2px rgba(50, 50, 50, 0.4);
    -moz-box-shadow:    0px 2px 13px 2px rgba(50, 50, 50, 0.4);
    box-shadow:         0px 2px 13px 2px rgba(50, 50, 50, 0.4);
    font-size: 1.5em;
    color: rgb(29, 27, 27);
    margin-top: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .SVItemRows{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: #5e5e5e;
    margin-top: 0.5em;
    margin-left: 0.5em;
    margin-block-end: 0.5em;
  }

  @media print {
    #SViewer {
      display: none;
    }
  }
</style>