<template>
  <div style="height: 90vh;
        overflow-y: auto">
    <div
      id="app"
      style="
        margin-top: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      "
    ></div>
    <div class="content-buttons">
      <button @click="audioBook" class="buttonAction" id="prev">
        Anterior
      </button>
      <button class="buttonAction" id="zoom_out"><i style="font-size:14px" class="icon-zoom-out"></i></button>
      <button class="buttonAction" id="zoom"><i style="font-size:14px" class="icon-zoom-in"></i></button>
      <button @click="audioBook" class="buttonAction" id="next">Próximo</button>

      <audio id="audio" src="../../public/audio/movement-paper.wav"></audio>
    </div>
  </div>
</template>

<script>
import * as book from "../flipbook/book-pdf";
import scroll from "../directives/scroll";

export default {
  name: "VReaderMode",

  data() {
    return {
      searchController: null,
      pageCount: null,
      page: null,
      pageRotateCw: true,
    };
  },

  mounted() {
    this.startDoc(this.pdf);
  },

  directives: {
    scroll,
  },

  props: {
    pdf: String,
    isPublic: Boolean,
    canPrint: Boolean,
    canDownload: Boolean,
    title: String,
  },
  methods: {
    startDoc(pdf) {
      book.main(pdf);
    },

    audioBook() {
      var audio = document.getElementById("audio");
      audio.volume = 0.2;
      audio.play();
    }
  },
};
</script>

<style>
.content-buttons {
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  width: max(25%, 300px);
  height: auto;
  align-items: center;
  justify-content: space-around;
  padding: 8px 12px;
  background: #36383a;
  opacity: 0.7;
  transition: 0.5s;
}
.content-buttons:hover {
  opacity: 1;
}
.buttonAction {
  width: 80px;
  height: 40px;
  border-radius: 6px;
  border: none;
  margin: 0 5px;
  background-color: #1e77b8;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;
}
.buttonAction:hover {
  opacity: 0.7;
}
</style>
