<template>
  <div>
    <div class="fade"></div>
    <div class="Modal">
      <img src="./close.png" />
      <h3 class="title">{{ title }}</h3>
      <p class="text">{{ text }}</p>
      <button @click="$emit('close')">Fechar</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalSimple",
  props: {
    title: String,
    text: String,
  },
};
</script>

<style scoped>
.fade {
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 5;
}
.Modal {
  background: #ffffff;
  width: 450px;
  height: 270px;
  border: none;
  border-radius: 10px;
  padding: 20px;
  /* centralizado */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Modal > h1 {
  font-size: 23px;
  font-family: Verdana;
  text-transform: uppercase;
  color: rgb(95, 95, 95);
  margin-top: 20px;
}
.title {
  font-size: 23px;
  font-family: Verdana;
  text-transform: uppercase;
  color: #f84848;
  margin-bottom: 20px;
}
.text {
  font-size: 18px;
  font-family: Verdana;
}
img {
  width: 80px;
  margin-bottom: 30px;
}
button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background: #f84848;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
</style>
