<template>
  <div>
    <div class="fade"></div>
    <div class="Modal">
      <svg viewBox="25 25 50 50">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
      <h1>Carregando</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingModal",
};
</script>

<style scoped>
.fade {
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
}
.Modal {
  background: #ffffff;
  width: 450px;
  height: 270px;
  border: none;
  border-radius: 10px;
  padding: 20px;
  /* centralizado */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Modal > h1 {
  font-size: 23px;
  font-family: Verdana;
  text-transform: uppercase;
  color: rgb(95, 95, 95);
  margin-top: 20px;
}
/* Animação Loader */
svg {
  width: 100px;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

circle {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 5;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}
</style>
