import Vue from 'vue'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VuePdfApp from "vue-pdf-app-with-sign"

Vue.component("vue-pdf-app", VuePdfApp);

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
