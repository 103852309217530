<template>
  <div>
    <div id="doc-viewer">
      <vue-pdf-app
        @pages-rendered="pagesRenderedHandler"
        style="flex: 1 margin: 1%;"
        :pdf="pdf"
        :config="config"
        :fileName="title"
        :title="true"
      >
      </vue-pdf-app>
    </div>
  </div>
</template>

<script>
import scroll from "../directives/scroll";

import "vue-pdf-app-with-sign/dist/icons/main.css";

export default {
  name: "VDocument",

  directives: {
    scroll,
  },

  data() {
    return {
      searchController: null,
      pdf: null,
      pageCount: null,
      page: null,
      pageRotateCw: true,
      config: {
        secondaryToolbar: {
          firstPage: true,
          lastPage: true,
          cursorHandTool: true,
          scrollVertical: true,
          scrollHorizontal: true,
          scrollWrapped: true,
          spreadNone: true,
          spreadOdd: true,
          spreadEven: true,
          documentProperties: false,
          cursorSelectTool: true,
          pageRotateCw: true,
          pageRotateCcw: true,
        },
        toolbar: {
          toolbarViewerRight: {
            openFile: false,
            download: this.canDownload,
            print: false,
            viewBookmark: false,
          },
        },
      },
    };
  },

  watch: {
    url: {
      handler(url) {
        this.startDoc(url);
      },
    },
    canPrint: {
      handler() {
        this.config.toolbar.toolbarViewerRight.print = false;
      },
    },
    canDownload: {
      handler(canDownload) {
        this.config.toolbar.toolbarViewerRight.download =
          canDownload && !this.isPublic;
      },
    },
  },

  props: {
    url: String,
    isPublic: Boolean,
    canPrint: Boolean,
    canDownload: Boolean,
    title: String,
  },

  methods: {
    startDoc(url) {
      this.pdf = this.base64toURLBlob(url);
    },

    base64toURLBlob(b64Data) {
      var byteCharacters = Buffer.from(b64Data, "base64");
      var uint8Array = new Uint8Array(byteCharacters.length);
      for (var i = 0; i < uint8Array.length; i++) {
        uint8Array[i] = byteCharacters[i];
      }

      return uint8Array.buffer;
    },

    pagesRenderedHandler(pdfApp) {
      setTimeout(() => {
        pdfApp.pdfViewer.currentScaleValue = 1;
      });
    },
  },
};
</script>

<style scoped>
#doc-viewer {
  position: absolute;
  top: 5em;
  right: 0;
  bottom: 0;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
</style>
