<template>
  <div>
    <section id="viewerDoc">
      <VHeader
        :title="title"
        :logo="logo"
        :isPublic="isPublic"
        :canPrint="canPrint"
        :canDownload="canDownload"
        :url="url"
        :hasSign="signers.length > 0"
        :extension="extension"
        :viewerMovie="viewerMovie"
        @activeReaderMode="action()"
        :readerBoolean="readerBoolean"
      />

      <VDocument
        v-if="!viewerMovie && !viewerTiff && !readerBoolean"
        :isPublic="isPublic"
        :canPrint="canPrint"
        :canDownload="canDownload"
        :title="`${title}.pdf`"
        :url="url"
      />

      <VSideBar :Signers="signers" />
    </section>

    <div
      class="d-flex justify-content:center align:center mx-auto fill-height"
      :style="{ width: '100%' }"
    >
      <VVideoPlayer
        v-if="viewerMovie && !viewerTiff"
        :extension="extension"
        :url="url"
        :canDownload="canDownload"
      />
    </div>
    <div>
      <VDocumentTiff
        v-if="viewerTiff"
        :url="url"
        :isPublic="isPublic"
        :title="`${title}`"
        :pageCounts="`${pageCounts}`"
      />
    </div>
    <div v-if="readerBoolean">
      <VReaderMode
        :pdf="pdf"
        :title="`${title}`"
        :isPublic="isPublic"
        :canPrint="canPrint"
        :canDownload="canDownload"
        :pageCounts="pageCounts"
      />
    </div>
  </div>
</template>
<script src="js/three.min.js"></script>
<script src="js/pdf.min.js"></script>

<script src="js/3dflipbook.min.js"></script>
<script>
import "vue-pdf-app-with-sign/dist/icons/main.css";

import VHeader from "./VHeader";
import VSideBar from "./VSideBar";
import VDocument from "./VDocument";

import VVideoPlayer from "./VPlayerVideo.vue";
import VDocumentTiff from "./VDocumentTiff.vue";
import VReaderMode from "./VReaderMode.vue";

export default {
  name: "DDViewer",

  components: {
    VHeader,
    VDocument,
    VSideBar,
    VVideoPlayer,
    VDocumentTiff,
    VReaderMode,
  },

  props: {
    url: String,
    title: String,
    logo: String,
    isPublic: Boolean,
    canPrint: Boolean,
    canDownload: Boolean,
    isOpenModal: Boolean,
    titleModal: String,
    contentModal: String,
    classModal: String,
    baseUrlRedirect: String,
    isSearchTerm: String,
    signers: Array,
    extension: String,
    viewerMovie: Boolean,
    viewerThumb: Boolean,
    viewerTiff: Boolean,
    pageCounts: Number,
    readerModeBool: Boolean,
  },

  data() {
    return {
      readerBoolean: false,
      cScale: 1,
      currentPage: 1,
      searchController: null,
      linkService: null,
      pdfByte: null,
      extVideo: ["mp4", "webm", "ogg"],
      config3: {
        toolbar: false,
      },
      pdf: null,
      opts: {
        width: 800,
        height: 600,
      },
    };
  },
  methods: {
    isVideo(extension, extVideo) {
      return this.in_array(extension, extVideo);
    },

    startDoc() {
      this.pdf = this.base64toURLBlob(this.url);
    },

    base64toURLBlob(b64Data) {
      var byteCharacters = Buffer.from(b64Data, "base64");
      var uint8Array = new Uint8Array(byteCharacters.length);
      for (var i = 0; i < uint8Array.length; i++) {
        uint8Array[i] = byteCharacters[i];
      }

      var a = new Blob([uint8Array.buffer], { type: "application/pdf" });
      return URL.createObjectURL(a);
    },

    action() {
      this.readerBoolean = !this.readerBoolean;
      if (!this.readerBoolean) {
        location.reload();
      } else {
        this.startDoc();
      }
    },
  },
};
</script>

<style scoped>
.viewerContent {
  width: 70%;
  top: 5px;
  left: 0;
  background: #42b983;
}
.content-body {
  display: flex;
  align-items: center;
  height: 680px;
  justify-content: center;
}
</style>
