<template>
  <div>
    <LoadingModal v-show="false" />
    <ModalSimple
      v-on:close="closeModal()"
      :title="titleModal"
      :text="textModal"
      v-show="modalError"
    />
    <div>
      <div class="content-buttons" v-if="!modalLoadingBool">
        <input
          type="number"
          class="inputPages"
          v-model="count"
          v-on:keyup.enter="pageInput"
          value="count"
        />
        <p class="textCounter">de {{ pageCounts }}</p>

        <button @click="removeOne" class="buttonAction">Anterior</button>
        <button @click="AddOne" class="buttonAction">Proximo</button>
      </div>
      <div class="content-image">
        <img id="img" src="" />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingModal from "./DDLoading.vue";
import UTIF from "./libraryTiff/UTIF";
import ModalSimple from "./modal/modalUtili.vue";

export default {
  name: "VDocumentTiff",

  components: {
    LoadingModal,
    ModalSimple,
  },

  directives: {
    scroll,
  },

  data() {
    let modalLoadingBool = true;
    return {
      modalLoadingBool,
      source: String,
      count: 1,
      history: String,
      textModal: "Digite o numero correto",
      titleModal: "Erro ao procurar",
      modalError: false,
    };
  },

  props: {
    url: String,
    isPublic: Boolean,
    canPrint: Boolean,
    canDownload: Boolean,
    title: String,
    pageCounts: null,
  },

  watch: {
    url: {
      handler() {
        this.startDoc(this.count);
      },
    },
    canPrint: {
      handler() {
        this.config.toolbar.toolbarViewerRight.print = false;
      },
    },
    canDownload: {
      handler(canDownload) {
        this.config.toolbar.toolbarViewerRight.download =
          canDownload && !this.isPublic;
      },
    },
  },
  methods: {
    startDoc(index) {
      this.modalLoadingBool = true;
      var b64 = this.base64ToArrayBuffer(this.url);
      let indexNew = index - 1;
      this.onImageReady(b64, indexNew);
    },

    base64ToArrayBuffer(base64) {
      return Buffer.from(base64, "base64");
    },
    onImageReady(xhr, index) {
      var ifds = UTIF.decode(xhr);
      UTIF.decodeImage(xhr, ifds[index]);
      var rgba = UTIF.toRGBA8(ifds[index]);
      var w = ifds[index].width;
      var h = ifds[index].height;
      var cnv = document.createElement("canvas");
      cnv.width = w;
      cnv.height = h;
      var ctx = cnv.getContext("2d");
      var imgd = new ImageData(new Uint8ClampedArray(rgba.buffer), w, h);
      ctx.putImageData(imgd, 0, 0);
      var sendImage = cnv.toDataURL();
      document.querySelector("#img").setAttribute("src", sendImage);
      this.modalLoadingBool = false;
    },
    removeOne() {
      if (this.count > 1) {
        this.count -= 1;
        this.startDoc(this.count);
      }
    },
    AddOne() {
      let Count = parseInt(this.count);
      let PageCount = parseInt(this.pageCounts);
      if (Count >= PageCount) {
        return;
      }
      this.count++;
      this.startDoc(this.count);
    },
    pageInput() {
      //Verifica se contem ponto, para numeros quebrados
      if (this.count.search(".") != -1) {
        const numberInt = this.count.split(".");
        this.count = numberInt[0];
      }
      this.modalLoadingBool = true;
      let Count = parseInt(this.count);
      let PageCount = parseInt(this.pageCounts);
      // this.modalLoadingBool = false;

      const verifyCount = Count < 1 || Count > PageCount || this.count == "";

      if (verifyCount) {
        this.modalLoadingBool = false;
        return (this.modalError = true);
      }
      this.startDoc(Count);
      return this.count;
    },
    closeModal() {
      this.modalError = false;
    },
  },
};
</script>
<style>
.textCounter {
  display: inline;
  color: #fff;
  font-family: sans-serif;
  font-size: 16px;
  margin-right: 10px;
}
.inputPages {
  padding: 5px 10px;
  color: #fff;
  background-color: rgb(108, 108, 108);
  border-radius: 7px;
  border: 1px solid gray;
  outline: none;
  width: 30px;
  margin-right: 7px;
}
.content-buttons {
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  width: max(25%, 300px);
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background: #36383a;
  opacity: 0.7;
  transition: 0.5s;
}
.content-buttons:hover {
  opacity: 1;
}
.buttonAction {
  width: 80px;
  height: 40px;
  border-radius: 6px;
  border: none;
  margin: 0 5px;
  background-color: #1e77b8;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;
}
.buttonAction:hover {
  opacity: 0.7;
}
.content-image {
  display: flex;
  width: 100%;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
  overflow: auto;
  height: 90vh;
}

.content-image img {
  width: max(31%, 350px);
  margin-top: 90px;
  margin-bottom: 65px;
}
/* Retira a marcação do input */
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
