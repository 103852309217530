<template>
  <header id="HViewer">
    <section class="HVContent">
      <div class="HVCompanyMarca" id="HVCompanyMarca" @click="redirectUrlBase">
        <img src="../assets/img/digitaldoc.png" class="logoCompany" />
      </div>
      <div class="HVDocumentName" id="HVDocumentName">
        <a
          alt="titulo do documento dentro do digital doc"
          style="font-family: sans-serif"
        >
          <i class="icon-file mg-right"></i>
          {{ title }}
        </a>
      </div>

      <div class="HVToolbarButtons">
        <button
          v-if="verifyExtension"
          v-bind:class="{btnPinter: !rmodeBool, activeButton: rmodeBool}"
          @click="$emit('activeReaderMode'), activeReaderMode()"
        >
          <i class="icon-book-reading"></i>
        </button>
        <button
          v-if="canDownload && !isPublic"
          @click="downloadDoc"
          id="HVPrinter"
          class="btnPinter"
        >
          <i class="icon-download"></i>
        </button>

        <button
          v-if="canPrint && !isPublic && !viewerMovie"
          @click="printDoc"
          id="HVPrinter"
          class="btnPinter"
        >
          <i class="icon-printer"></i>
        </button>

        <button
          v-if="hasSign"
          id="HVSearch"
          @click="openSignDoc"
          class="btnSearch"
        >
          <i class="icon-pen"></i>
        </button>
      </div>
    </section>
  </header>
</template>

<script>
// import fileDownload from 'js-file-download';
export default {
  name: "VHeader",

  data() {
    return {
      verifyExtension: false,
      rmodeBool: false
    };
  },

  props: {
    url: String,
    title: String,
    canPrint: Boolean,
    canDownload: Boolean,
    isPublic: Boolean,
    logo: String,
    hasSign: Boolean,
    extension: String,
    viewerMovie: Boolean,
    readerModeBool: Boolean,
  },

  mounted() {
    this.verifyExtensions();
  },

  beforeUpdate() {
    this.verifyExtensions();
  },
  
  methods: {
    redirectUrlBase() {
      let data = sessionStorage.getItem("rev");
      let dataAndRevision = JSON.parse(decodeURIComponent(data));
      window.location.href = dataAndRevision.infos.urlBase;
    },
    close() {
      window.close();
    },

    verifyExtensions() {
      var verifyExt =
        this.extension !== "mp4" &&
        this.extension !== "tiff" &&
        this.extension !== "webm" &&
        this.extension !== "ogg" &&
        this.extension !== "tif";

      if (verifyExt) {
        this.verifyExtension = true;
      } else {
        this.verifyExtension = false;
      }
    },

    async activeReaderMode() {
      this.rmodeBool = !this.readerModeBool
    },

    async printDoc() {
      let byteCharacters = atob(this.url);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: "application/pdf;base64" });
      let fileURL = URL.createObjectURL(file);
      await this.printFile(fileURL);
    },

    async downloadDoc() {
      const dataAndRevision = JSON.parse(decodeURIComponent(sessionStorage.getItem("rev")));
      const {data:generate} = await this.axios.get(`${dataAndRevision.webApiUrl}revisions/file/generate/${dataAndRevision.revision.id}`)
      window.open(`${dataAndRevision.webApiUrl}revisions/file/hash/${generate}&download=true`)
    },

    async openSignDoc() {
      let id = this.isMobile() ? "SViewer-mobile" : "SViewer";
      let HVSearchInput = document.getElementById(id);
      if (HVSearchInput.style.visibility == "visible") {
        HVSearchInput.style.visibility = "hidden";
      } else {
        HVSearchInput.style.visibility = "visible";
      }
    },

    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    async printFile(urlFile) {
      let iframe = document.createElement("iframe");
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = urlFile;

      iframe.onload = () => {
        setTimeout(() => {
          iframe.focus();
          iframe.contentWindow.print();
        }, 0.3);
      };
    },
  },
};
</script>

<style scoped>
#HViewer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5em;
  background: #1e77b8;
}

#HViewer .HVContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
  margin-top: 0.3em;
}

#HViewer .HVContent .HVCompanyMarca {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 80%;
  padding: 0.5em 0;
  margin-top: 0.5em;
}

#HViewer .HVContent .HVCompanyMarca img {
  max-width: 100%;
  max-height: 100%;
}

#HViewer .HVContent .HVCompanyMarca img.logoCompany:hover {
  cursor: pointer;
}

#HViewer .HVContent .HVDocumentName {
  display: flex;
  flex-direction: row;
  width: 65%;
  font-size: 1.8em;
  color: #fff;
  padding-left: 0.5em;
}

#HViewer .HVContent .HVToolbarButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  height: 80%;
  padding-right: 1em;
}

#HViewer .HVContent .HVToolbarButtons button {
  outline: 0;
  background: transparent;
  border: none;
  border-radius: 0.4em;
  margin-right: 0.5em;
  color: #fefefe;
  font-size: 2em;
  font-weight: bold;
  padding: 0.3em 0.6em;
}

#HViewer .HVContent .HVToolbarButtons button:hover {
  cursor: pointer;
  background: #1b6da7;
  border: none;
}

#HViewer .HVContent .mg-right {
  margin-right: 1em;
}

@media print {
  #HViewer {
    display: none;
  }
}

#HViewer .HVContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
  margin-top: 0.3em;
}

#HViewer .HVContent .HVCompanyMarca {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 80%;
  padding: 0.5em 0;
  margin-top: 0.5em;
}

#HViewer .HVContent .HVCompanyMarca img {
  max-width: 100%;
  max-height: 100%;
}

#HViewer .HVContent .HVCompanyMarca img.logoCompany:hover {
  cursor: pointer;
}

#HViewer .HVContent .HVDocumentName {
  display: flex;
  flex-direction: row;
  width: 65%;
  font-size: 1.8em;
  color: #fff;
  padding-left: 0.5em;
}

#HViewer .HVContent .HVToolbarButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  height: 80%;
  padding-right: 1em;
}

#HViewer .HVContent .HVToolbarButtons button {
  outline: 0;
  background: transparent;
  border-radius: 0.4em;
  margin-right: 0.5em;
  color: #fefefe;
  font-size: 2em;
  font-weight: bold;
  padding: 0.3em 0.6em;
  transition: 0.4s;
}

#HViewer .HVContent .HVToolbarButtons button:hover {
  cursor: pointer;
  background: #2042775e;
}

#HViewer .HVContent .mg-right {
  margin-right: 1em;
}

.activeButton {
  background: #2042775e !important;
}

@media print {
  #HViewer {
    display: none;
  }
}
</style>
