<template>
  <div id="AppViewer">
    <DDViewer
      v-bind="{
        url,
        title,
        logo,
        isPublic,
        canPrint,
        canDownload,
        isOpenModal,
        titleModal,
        contentModal,
        classModal,
        baseUrlRedirect,
        isSearchTerm,
        signers,
        extension,
        viewerMovie,
        viewerThumb,
        viewerTiff,
        urlToTiff,
        pageCounts,
        readerModeBool
      }"
    ></DDViewer>
    <LoadingModal v-if="CompleteLoading" />
  </div>
</template>

<script>
import DDViewer from "./components/DDViewer.vue";
import LoadingModal from "./components/DDLoading.vue";

export default {
  name: "app",

  components: {
    DDViewer,
    LoadingModal,
  },

  data() {
    return {
      url: "",
      title: "",
      urlToTiff: "",
      pageCounts: null,
      logo: "",
      canPrint: false,
      canDownload: false,
      isPublic: false,
      viewerMovie: false,
      viewerThumb: false,
      viewerTiff: false,
      baseUrlRedirect: "",
      isOpenModal: false,
      signers: [],
      titleModal: "",
      contentModal: "",
      classModal: "",
      isSearchTerm: "",
      webApiUrl: "",
      detailsCompany: "",
      extension: "",
      idDocument: "",
      CompleteLoading: true,
      readerModeBool: false,
    };
  },

  methods: {
    in_array(dataSearch, array) {
      for (let i in array) {
        if (array[i] == dataSearch) return true;
      }
      return false;
    },
    isThumb(extension, extThumb) {
      return this.in_array(extension, extThumb);
    },
    isVideo(extension, extVideo) {
      return this.in_array(extension, extVideo);
    },
    isTiff(extension, extTiff) {
      return this.in_array(extension, extTiff);
    },

    limitWords(words, limit) {
      let maxLength = limit;
      let cWords = "";
      cWords =
        words.length > maxLength
          ? words.substring(0, maxLength) + " ..."
          : words;
      return cWords;
    },

    async startDocViewer() {
      let currentURL = window.location.href;
      let cURL =
        currentURL.split("/#/").length > 1 ? currentURL.split("/#/") : null;
      let opURL = "";
      // this.extension.toLowerCase();
      let extThumb = ["jpg", "jpeg", "png", "gif"];
      let extVideo = ["mp4", "webm", "ogg"];
      let extTiff = ["tif", "tiff"];
      if (cURL == null) {
        sessionStorage.removeItem("rev");
        window.close();
      }

      opURL = cURL[1].split("/").length > 1 ? cURL[1].split("/") : null;

      if (opURL == null) {
        sessionStorage.removeItem("file");
        sessionStorage.removeItem("filePrint");
        sessionStorage.setItem("rev", cURL[1]);

        let dataAndRevision = JSON.parse(decodeURIComponent(cURL[1]));
        this.populateToolBar(dataAndRevision);
        this.webApiUrl = dataAndRevision.webApiUrl;
        this.detailsCompany = dataAndRevision.detailsCompany;

        if (this.isThumb(dataAndRevision.revision.extension, extThumb)) {
          this.viewerThumb = true;
        } else if (dataAndRevision.infos.canSearch) {
          this.isSearchTerm = dataAndRevision.infos.searchTerm;
        } else if (this.isVideo(dataAndRevision.revision.extension, extVideo)) {
          this.viewerMovie = true;
        } else if (this.isTiff(dataAndRevision.revision.extension, extTiff)) {
          this.viewerTiff = true;
        }

        history.pushState(
          dataAndRevision,
          "Digitaldoc V4 - Viewer",
          `#/${dataAndRevision.revision.id}/${dataAndRevision.revision.extension}`
        );
      } else {
        if (opURL[0] === "public") {
          this.isPublic = true;
          let dataAndRevision = JSON.parse(decodeURIComponent(opURL[2]));

          let response = await this.axios.get(
            `${dataAndRevision.webApiUrl}viewer/public/` +
              dataAndRevision.RowGuid
          );

          if (!response.data.isPublic) {
            this.setModal(
              "Acessando Arquivos",
              "Agradecemos o interesse nesse arquivo. \n Entretanto o mesmo não está mais publico !!!",
              "md-info"
            );
          }
          if (response.data.file) {
            if (this.isThumb(response.data.extension, extThumb)) {
              this.viewerThumb = true;
            } else if (
              this.isVideo(dataAndRevision.revision.extension, extVideo)
            ) {
              this.viewerMovie = true;
            } else if (
              this.isTiff(dataAndRevision.revision.extension, extTiff)
            ) {
              this.viewerTiff = true;
            }

            this.isSearchTerm = null;
            this.url = response.data.file;
            this.title = this.limitWords(response.data.title, 65);

            sessionStorage.removeItem("file");
            sessionStorage.removeItem("filePrint");
          }
        } else {
          // Deu refresh!!! Obtém os dados da sessionStorage

          let data = sessionStorage.getItem("rev");

          if (data != null) {
            let dataAndRevision = JSON.parse(decodeURIComponent(data));
            this.populateToolBar(dataAndRevision);
            this.detailsCompany = dataAndRevision.detailsCompany;
            this.webApiUrl = dataAndRevision.webApiUrl;

            if (this.isThumb(dataAndRevision.revision.extension, extThumb)) {
              this.viewerThumb = true;
            } else if (
              this.isVideo(dataAndRevision.revision.extension, extVideo)
            ) {
              this.viewerMovie = true;
            } else if (
              this.isTiff(dataAndRevision.revision.extension, extTiff)
            ) {
              this.viewerTiff = true;
            }

            if (dataAndRevision.infos.canSearch)
              this.isSearchTerm = dataAndRevision.infos.searchTerm;

            if (opURL[0] != dataAndRevision.revision.id) {
              sessionStorage.removeItem("rev");
              window.close();
            }
          } else {
            // Primeira vez salva os dados na sessionStorage (Caso especifico caiu aqui!)
            sessionStorage.removeItem("file");
            sessionStorage.removeItem("filePrint");
            sessionStorage.setItem("rev", cURL[1]);

            let dataAndRevision = JSON.parse(decodeURIComponent(cURL[1]));
            this.populateToolBar(dataAndRevision);
            this.detailsCompany = dataAndRevision.detailsCompany;
            this.webApiUrl = dataAndRevision.webApiUrl;
            if (this.isThumb(dataAndRevision.revision.extension, extThumb)) {
              this.viewerThumb = true;
            } else if (
              this.isVideo(dataAndRevision.revision.extension, extVideo)
            ) {
              this.viewerMovie = true;
            } else if (dataAndRevision.infos.canSearch) {
              this.isSearchTerm = dataAndRevision.infos.searchTerm;
            } else if (
              this.isTiff(dataAndRevision.revision.extension, extTiff)
            ) {
              this.viewerTiff = true;
            }

            history.pushState(
              dataAndRevision,
              "Digitaldoc V4 - Viewer",
              `#/${dataAndRevision.revision.id}/${dataAndRevision.revision.extension}`
            );
          }
        }
      }
      this.logo = `/assets/img/${this.detailsCompany}.png`;
    },

    populateToolBar(dataAndRevision) {
      this.canPrint = dataAndRevision.infos.canPrint;
      this.canDownload = dataAndRevision.infos.canDownload;
      this.extension = dataAndRevision.revision.extension;

      if (Object.keys(dataAndRevision.signers).length != 0) {
        this.signers = dataAndRevision.signers;
      } else {
        this.signers = [];
      }
    },

    async getDoc() {
      let data = sessionStorage.getItem("rev");
      if (data) {
        let revisionData = JSON.parse(decodeURIComponent(data));
        let response = await this.axios.post(`${this.webApiUrl}viewer/`, {
          ...revisionData.revision,
        });
        this.CompleteLoading = false;
        this.url = response.data;
        this.title = this.limitWords(revisionData.revision.title, 65);
        this.extension = revisionData.revision.extension;
        this.pageCounts = revisionData.revision.pageCount;

      }
    },
    setModal(mTitle, mContent, mClass = null, mRedirect = null) {
      this.isOpenModal = true;
      this.titleModal = mTitle;
      this.contentModal = mContent;
      this.classModal = mClass != null ? mClass : "md-info";
      this.baseUrlRedirect =
        mRedirect != null ? mRedirect : "http://www.digitaldoc.com.br";
    },
  },
  BeforeCreated() {
    this.CompleteLoading = true;
  },
  async mounted() {
    await this.startDocViewer();
    await this.getDoc();
  },
};
</script>

<style>
@media print {
  body {
    background-color: transparent;
  }
}
</style>
