var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"AppViewer"}},[_c('DDViewer',_vm._b({},'DDViewer',{
      url: _vm.url,
      title: _vm.title,
      logo: _vm.logo,
      isPublic: _vm.isPublic,
      canPrint: _vm.canPrint,
      canDownload: _vm.canDownload,
      isOpenModal: _vm.isOpenModal,
      titleModal: _vm.titleModal,
      contentModal: _vm.contentModal,
      classModal: _vm.classModal,
      baseUrlRedirect: _vm.baseUrlRedirect,
      isSearchTerm: _vm.isSearchTerm,
      signers: _vm.signers,
      extension: _vm.extension,
      viewerMovie: _vm.viewerMovie,
      viewerThumb: _vm.viewerThumb,
      viewerTiff: _vm.viewerTiff,
      urlToTiff: _vm.urlToTiff,
      pageCounts: _vm.pageCounts,
      readerModeBool: _vm.readerModeBool
    },false)),(_vm.CompleteLoading)?_c('LoadingModal'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }