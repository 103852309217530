<template>
  <div>
    <LoadingModal v-show="!modalLoadingBool" />
    <div v-show="modalLoadingBool" class="player" style="margin-top: 10px">
      <div class="player-sizer">
        <video
          width="70%"
          height="70%"
          preload="none"
          controls
          :fileName="title"
          controlsList="nodownload"
          type="video/*"
          :src="movie"
        >
          <source />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingModal from "./DDLoading.vue";

export default {
  name: "VideoPlayer",
  data() {
    return {
      movie: null,
      modalLoadingBool: false,
    };
  },
  components: {
    LoadingModal,
  },
  props: {
    url: String,
    title: String,
    videoOptions: {},
  },
  methods: {
    changeVideoPlaying(url) {
      this.movie = this.base64toURLBlob(url);
      this.modalLoadingBool = true;
    },

    base64toURLBlob(b64Data) {
      var byteCharacters = Buffer.from(b64Data, "base64");
      var uint8Array = new Uint8Array(byteCharacters.length);
      for (var i = 0; i < uint8Array.length; i++) {
        uint8Array[i] = byteCharacters[i];
      }

      var movie = new Blob([uint8Array.buffer], { type: "video/*" });
      return URL.createObjectURL(movie);
    },
  },

  watch: {
    url: {
      handler(url) {
        this.changeVideoPlaying(url);
      },
    },
  },
};
</script>
<style scoped>
.player {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 60px;
}
.player video {
  width: 95%;
  border-radius: 4px;
}

.player-sizer {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10em;
}

</style>
